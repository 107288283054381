import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/warrior.webp'
import Graph from '../components/graph'

import Mail from '../components/mail'
import MyVideo from '../components/video'
import More from '../components/more'
class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Warrior";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Overcomes obstacles. Wants to win."/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>


            <h2>{name}</h2>
            
            <p>The Warrior archetype is one that should not be underestimated or overlooked. It is a force to be reckoned with, one that can either bring us to great heights of achievement or lead us down a path of destruction. The Warrior is a symbol of courage and determination, a force that helps us set and achieve our goals, overcome obstacles, and persist in difficult times. But it is also a force that can be destructive if not properly harnessed. It is a force that sees others as enemies and that thinks in either/or terms.
The Warrior is a relatively simple creature, seeking simply to win whatever confronts them. This includes the dragons that live inside the mind, the underlying fears and doubts that can hold us back from achieving our goals. But the Warrior's true challenge is to bring meaning to what they do, to choose their battles wisely, and to use their courage and discipline to achieve their goals.
But what happens when the Warrior archetype takes over and controls our every thought and action? What happens when it leads us down a path of destruction, destroying our relationships and our sense of self? This is a question that many of us have asked ourselves, and it is one that we must all grapple with as we strive to achieve our goals and overcome our obstacles.
To truly understand the Warrior archetype and how to harness its power, I recommend reading the e-book <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=warrior"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> This e-book will help you understand the Warrior archetype and how to use its power to achieve your goals and overcome your obstacles, without letting it control and destroy your life and relationships. Remember, the Warrior is a force to be reckoned with, but it is one that can be tamed and used for good.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (

  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
